import * as _util2 from "util";

var _util = "default" in _util2 ? _util2.default : _util2;

import * as _uuid2 from "uuid";

var _uuid = "default" in _uuid2 ? _uuid2.default : _uuid2;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.jsonSchema = exports.fromString = exports.empty = exports.isUuid = exports.regex = exports.uuid = void 0;
const util_1 = _util;
const uuid_1 = _uuid;
const regex = {
  v4: /(?:^[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[a-f0-9]{4}-[a-f0-9]{12}$)|(?:^0{8}-0{4}-0{4}-0{4}-0{12}$)/u,
  v5: /(?:^[a-f0-9]{8}-[a-f0-9]{4}-5[a-f0-9]{3}-[a-f0-9]{4}-[a-f0-9]{12}$)|(?:^0{8}-0{4}-0{4}-0{4}-0{12}$)/u
};
exports.regex = regex;
const jsonSchema = {
  v4: {
    type: "string",
    pattern: regex.v4.toString().slice(1, -2)
  },
  v5: {
    type: "string",
    pattern: regex.v5.toString().slice(1, -2)
  }
};
exports.jsonSchema = jsonSchema;
const uuidv4 = (0, util_1.deprecate)(() => (0, uuid_1.v4)(), "uuidv4() is deprecated. Use v4() from the uuid module instead.");
exports.uuid = uuidv4;
const isUuid = (0, util_1.deprecate)(value => (0, uuid_1.validate)(value) && ((0, uuid_1.version)(value) === 4 || (0, uuid_1.version)(value) === 5), "isUuid() is deprecated. Use validate() from the uuid module instead.");
exports.isUuid = isUuid;
const empty = (0, util_1.deprecate)(() => uuid_1.NIL, "empty() is deprecated. Use NIL from the uuid module instead.");
exports.empty = empty;
const fromString = (0, util_1.deprecate)((text, namespace = "bb5d0ffa-9a4c-4d7c-8fc2-0a7d2220ba45") => (0, uuid_1.v5)(text, namespace), "fromString() is deprecated. Use v5() from the uuid module instead.");
exports.fromString = fromString;
export default exports;
export const __esModule = exports.__esModule,
      uuid = exports.uuid;
const _jsonSchema = exports.jsonSchema,
      _fromString = exports.fromString,
      _empty = exports.empty,
      _isUuid = exports.isUuid,
      _regex = exports.regex;
export { _jsonSchema as jsonSchema, _fromString as fromString, _empty as empty, _isUuid as isUuid, _regex as regex };